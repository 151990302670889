import $ from 'jquery';
import { getCookie } from './getCookie.js';
import { openModal, closeModal } from './modal.js';

$(() => {
    const $searches = $('.search-tyres-by-vehicle');
    const ajax_url = window.sage.ajax_url;
    let LIST_OF_OPTIONS = null;
    let vehicleCookie = getCookie('userVehicle');
    const tabs = ['make', 'model', 'year', 'fitment'];

    $.ajax({
        url: ajax_url,
        type: 'POST',
        data: {
            action: 'get_tyre_vehicle_options',
        },
        success: function (response) {
            LIST_OF_OPTIONS = response.data;

            init();
        },
        error: function (response) {
            console.log(response);
        },
    });

    // Helpers
    function updateTitle(tab = 'make') {
        // capitalize first letter
        const title = tab.charAt(0).toUpperCase() + tab.slice(1);

        return title;
    }

    function grabOptions(tab = 'make', selectedOptions = false) {
        if (tab == 'make' || tab == 'model') {
            return LIST_OF_OPTIONS[tab];
        } else if (tab == 'year') {
            let model = LIST_OF_OPTIONS['model'].find(
                (model) => model.post_id == selectedOptions.model
            );

            return model.years;
        } else if (tab == 'fitment') {
            let model = LIST_OF_OPTIONS['model'].find(
                (model) => model.post_id == selectedOptions.model
            );

            let fitments = model.fitments.filter(
                (fitment) => fitment.year == selectedOptions.year
            );

            return fitments;
        }
    }

    function updateOptions(tab = 'make', search = '', selectedOptions = false) {
        const allOptions = grabOptions(tab, selectedOptions);
        let options = [];
        let html = '';

        if (search) {
            // filter by make.title
            options = allOptions.filter((option) => {
                let title = option;

                if (option.title) {
                    title = option.title;
                }

                return title.toLowerCase().includes(search.toLowerCase());
            });
        } else {
            options = allOptions;
        }

        if (options) {
            if (selectedOptions) {
                if (tab == 'model' && selectedOptions?.make) {
                    options = options.filter(
                        (option) => option.make == selectedOptions?.make
                    );
                }
            }

            // sort by title
            options.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });

            if (tab == 'fitment') {
                options.forEach((option) => {
                    let frontTyre = option.Front;

                    let front = {
                        title: frontTyre.title,
                        url: frontTyre.url,
                    };

                    // check if rear is available
                    let rear = null;

                    if (option.Rear) {
                        let rearTyre = option.Rear;

                        rear = {
                            title: rearTyre.title,
                            url: rearTyre.url,
                        };
                    }

                    html += `<a href="${front.url}" class="block text-right text-lg text-slate-400 hover:text-blue-950 font-semibold search-tyres-by-vehicle__loop-item-btn">
                        <span class="block">${front.title}</span>`;

                    if (rear) {
                        html += `<span class="block text-xs italic">${rear.title}</span>`;
                    }

                    html += `</a>`;
                });

                // if (!vehicleCookie) {
                setVehicleCookie(selectedOptions);
                // }

                if (html) {
                    return html;
                }
            }

            options.forEach((option) => {
                let value = option;
                let title = option;

                if (option.post_id) {
                    value = option.post_id;
                    title = option.title;
                }

                html += `<button value="${value}" class="text-lg text-slate-400 hover:text-blue-950 font-semibold search-tyres-by-vehicle__loop-item-btn">${title}</button>`;
            });

            return html;
        }

        return `<p>No ${tab}s found.</p>`;
    }

    function updateTab(tab = 'make') {
        const index = tabs.indexOf(tab);

        if (index < tabs.length - 1) {
            tab = tabs[index + 1];
        }

        return tab;
    }

    function updatePlaceholder(tab = 'make') {
        const title = updateTitle(tab);
        const placeholder = `Search by ${title}`;

        return placeholder;
    }

    function findNextTab(tab = 'make') {
        const index = tabs.indexOf(tab);

        tab = null;

        if (index < tabs.length) {
            tab = tabs[index + 1];
        }

        return tab;
    }

    function selectOption(tab = 'make', value = null, list = []) {
        list[tab] = value;

        return list;
    }

    function setVehicleCookie(selectedOptions = false) {
        //Cookie Time
        var d = new Date();
        var expires = 360;
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();

        document.cookie = `userVehicle=${JSON.stringify(
            selectedOptions
        )}; ${expires}; path=/`;
    }

    function setupVehicleSearch() {
        let activeTab = 'make';
        let selectedOptions = {
            make: null,
            model: null,
            year: null,
            fitment: null,
        };

        const $search = $(this); // Scope to the current instance
        const $input = $search.find('.search-tyres-by-vehicle__input');
        const $title = $search.find('.search-tyres-by-vehicle__title');
        const $container = $search.find('.search-tyres-by-vehicle__loop');
        const $items = $search.find('.search-tyres-by-vehicle__loop-item');

        $input.on('input', function () {
            const search = $(this).val();

            $container
                .find(`.search-tyres-by-vehicle__loop-item--${activeTab}`)
                .html(updateOptions(activeTab, search, selectedOptions));
        });

        // if press enter on input select first option
        $input.on('keypress', function (e) {
            if (e.which === 13) {
                const $first = $container
                    .find(
                        `.search-tyres-by-vehicle__loop-item--${activeTab} button:first`
                    )
                    .focus();

                $first.click();
            }
        });

        $search.on(
            'click',
            '.search-tyres-by-vehicle__loop-item-btn',
            function () {
                const $btn = $(this);
                const value = $btn.val();
                $input.val('');

                selectedOptions = selectOption(
                    activeTab,
                    value,
                    selectedOptions
                );

                activeTab = updateTab(activeTab);

                $title.html(updateTitle(activeTab));
                $input.attr('placeholder', updatePlaceholder(activeTab));

                $items.removeClass('active');

                $input.focus();

                $container
                    .find(`.search-tyres-by-vehicle__loop-item--${activeTab}`)
                    .html(updateOptions(activeTab, '', selectedOptions));

                setTimeout(() => {
                    $container
                        .find('.next')
                        .addClass('active')
                        .removeClass('next');

                    let next = findNextTab(activeTab);

                    if (next) {
                        $container
                            .find(
                                `.search-tyres-by-vehicle__loop-item--${next}`
                            )
                            .addClass('next');
                    }
                }, 100);
            }
        );
    }

    function loadVehicleModal(cookie = false) {
        $.ajax({
            type: 'post',
            url: ajax_url,
            data: {
                action: 'get_my_garage_modal',
                vehicle: JSON.parse(decodeURIComponent(cookie)),
                // nonce: nonce,
            },
            success: function (response) {
                if (response.success) {
                    $('#modal-container-content').html(response.data.html);

                    $('#modal-container-content')
                        .find('.search-tyres-by-vehicle')
                        .each(setupVehicleSearch);
                }
            },
            error: function (response) {
                console.log(response);
            },
        });
    }

    //
    function init() {
        // for each search form
        $searches.each(setupVehicleSearch);
    }

    // Modal
    $(document).on('click', '.open-modal-select-garage-empty', function () {
        openModal('Select your vehicle');
        loadVehicleModal();
    });

    $('.open-modal-select-garage').on('click', function () {
        openModal('My Garage');
        loadVehicleModal(vehicleCookie);
    });
});
