import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';

import accordion from './accordion';
import { flare } from '@flareapp/js';

import './smoth-scroll';
import './woocommerce';
import './post/table-content';
import './tooltip';
import './slide';
import './map';
import './tyre-select';
import './top-picks-tyre-size';
import './set-user-store';
import './mouse-hover';
import './add-to-cart';
import './modal-popup';
import './tab-control';
import './finder-tool';
import './booking';
import './calender';
import './select-store';
import './single-product';
import './checkout';
import './menu';
import './common';
import './shop';
import './model';
import './anchor';
import './next-best-product.js';
import './tyres-by-vehicle.js';

domReady(async () => {
    try {
        main();
    } catch (err) {
        console.error(err);
    }
});

/**
 * app.main
 */

const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }

    accordion();

    $(() => {
        setTimeout(() => {
            loadingPageStarter();
        }, 500);
    });
};

function checkScroll() {
    if (typeof $.progressBar !== 'undefined') {
        $.progressBar();
        $.anchorScrollCheck();
    }
    if (typeof $.hideHeaderInFooter !== 'undefined') {
        $.hideHeaderInFooter();
    }
}

function loadingPageStarter() {
    $('body').removeClass('wating-loading');
    if (typeof $.checkAlinksWithImage !== 'undefined') {
        $.checkAlinksWithImage();
    }
    checkScroll();
}

window.addEventListener('scroll', checkScroll);
$(window).on('load', loadingPageStarter);

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (window.sage.WP_ENV === 'production') {
    flare.light(window.sage.FLARE_JS_KEY);
}
